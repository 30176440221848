import React from 'react'
import { Grid, Typography } from '@mui/material';
import { transactionDetailsStyles } from '../style'
import { formatMoneyAddDecimalPointAndThousands } from "../../../util/PaymentUtils";


const TransactionItem = ({ transaction, classes }) => {

  const styles = classes || transactionDetailsStyles()

  const viewInvoice = () => {
    window.open(`/buyer-invoice/${transaction?.transactionNumber}`);
  };

  return (
    <Grid container justifyContent='space-between' alignItems='center' className={styles.penIcon}>
      <Typography className={styles.transactionLabel} onClick={viewInvoice}>{transaction.transactionNumber}</Typography>
      <Typography className={styles.title}>
        {transaction.firstIntervalTransactionStartDate + " - " + transaction.firstIntervalTransactionEndDate}
      </Typography>
      <Typography className={styles.title}>{`${formatMoneyAddDecimalPointAndThousands(transaction.buyerTransactionAmount)}`}</Typography>
    </Grid>
  )
}

export default TransactionItem
