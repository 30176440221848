import React, {useState, useCallback, useEffect} from 'react'
import { Box, Grid, Typography } from '@mui/material';
import { requestGetFailedTransactionsForBooking } from '../payment-requests';
import Busy from '../../Busy';
import {withSnackbar} from "../../hocs/withSnackbar";
import TransactionItem from "./TransactionItem";
import { transactionDetailsStyles } from '../style'
import Skeleton from "@mui/lab/Skeleton";
import { getErrorMessageForNonStandardAndStandardResponse } from '../../../util/NetworkErrorUtil';

const TransactionsList = ({ account, bookingId, paymentMethodId, setPaymentsTotal, classes, snackbarShowMessage }) => {

  const styles = classes || transactionDetailsStyles()

  const [transactions, setTransactions] = useState();

  console.log("TODO: link paymentMethodId to cost calculations", paymentMethodId)

  const loadTransactions = useCallback(() => {
    if(bookingId) {
      Busy.set(true)
      requestGetFailedTransactionsForBooking(bookingId)
        .then(({ body: transactionSummary }) => {
            setTransactions(transactionSummary.bookingTransactions.sort((a, b) => a.transactionNumber - b.transactionNumber));
            const total = {
              count: transactionSummary.failedTransactionCount,
              charge: transactionSummary.totalCharge,
              tax: 0,
              transactionFee: 0,
              total: 0
            }
            total.total = total.charge + total.tax + total.transactionFee;
            setPaymentsTotal(total);
            Busy.set(false);
        })
        .catch((error) => {
          snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(error));
          Busy.set(false);
        })
    }
  }, [account, setPaymentsTotal]);

  useEffect(() => {
    loadTransactions();
  }, [loadTransactions]);

  return (
    <Box>
      <Grid item container direction={'column'} justifyContent='space-between' alignItems='center'>
        <Typography className={styles.heading}>
          Transaction Details
        </Typography>
        {
          !transactions ?
            <Skeleton variant={'text'} width={'100%'}/>
          : !transactions.length ?
            <Typography className={styles.noTransactions}>No transactions found.</Typography>
          :
            <Box width={'100%'}>
              <Grid container justifyContent='space-between' alignItems='flex-end' className={styles.bookDetailsRow}>
                <Grid container justifyContent='space-between' alignItems='center' className={styles.penIcon}>
                  <Typography className={styles.title}>Invoice</Typography>
                  <Typography className={styles.title}>Service Dates</Typography>
                  <Typography className={styles.title}>Service Charge</Typography>
                </Grid>
              </Grid>
              {
                transactions.map((transaction) =>
                  <TransactionItem
                    transaction={transaction}
                  />
                )
              }
            </Box>
        }
      </Grid>
    </Box>
  )
}

export default withSnackbar(TransactionsList)
