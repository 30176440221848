import React, {useEffect, useState, useCallback} from 'react';
import {transformBooking} from '../../components/supplier-booking/data';
import {Container, Grid, Tab, Tabs, Typography} from "@mui/material";
import AdjustBookingDialog from '../../components/supplier-booking/AdjustBookingDialog';
import {TabContext, TabPanel} from "@mui/lab";
import type {Account} from "../../types/Account";
import BookingTab from '../../components/supplier-booking/BookingTab';
import {SupplierBookingFilter} from '../../components/supplier-booking';
import {withSnackbar} from '../../components/hocs/withSnackbar';
import {getErrorMessageForNonStandardAndStandardResponse} from '../../util/NetworkErrorUtil';
import {
  getSupplierBooking,
  getSupplierBookingCSVDownload,
  updateBookingStatus
} from '../../components/supplier-booking/requests/supplier-booking-requests';
import DownloadCSVButton from '../../components/report/export/DownloadCSVButton';
import {hasAdminAccess} from '../../util/AccountUtils';
import {useStyles} from './styles';
import type {Booking} from "../../types/Booking";
import useUrlQueryParams from "../../hooks/useUrlQueryParams";


export const adjustBookingTypes = {DATE: "End Date", SPACES: "Number of Spaces"};

const ACTIVE_TAB = '0'
const PENDING_TAB = '1';
const INACTIVE_TAB = '2';
const PAYMENT_FAILED_TAB = '3';

export const statuses = {ACTIVE: 'ACTIVE', FAILED: 'FAILED', INACTIVE: 'INACTIVE', PENDING:'PENDING'};
export const updatePendingBookingActions = {APPROVE: "approve", DECLINE: "decline"};

const SupplierBooking = (props: { account: Account, snackbarShowMessage: () => void }) => {
  const {account, snackbarShowMessage} = props;

  const urlParams = useUrlQueryParams();
  const urlBookingNumber = urlParams.bookingNumber || "";
  const urlStatus = urlParams.status || "";

  const getDefaultTab = useCallback((status) => {
    if (status) {
      const formattedStatus = status.toUpperCase();
      switch (formattedStatus) {
        case statuses.ACTIVE:
          return ACTIVE_TAB;
        case statuses.FAILED:
          return PAYMENT_FAILED_TAB;
        case statuses.INACTIVE:
          return INACTIVE_TAB;
        case statuses.PENDING:
          return PENDING_TAB;
        default:
          return ACTIVE_TAB;
      }
    } else {
      return ACTIVE_TAB
    }
  }, []);

  const defaultAdjustBooking = {open: false, booking: null, type: adjustBookingTypes.DATE};

  const [currentTab, setCurrentTab] = useState(getDefaultTab());
  const [activeBookings: Booking[], setActiveBookings] = useState([])
  const [inactiveBookings: Booking[], setInactiveBookings] = useState([]);
  const [failedBookings: Booking[], setFailedBookings] = useState([]);
  const [pendingBookings: Booking[], setPendingBookings] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const [failedPage, setFailedPage] = useState(0);
  const [inactivePage, setInactivePage] = useState(0);
  const [pendingPage, setPendingPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [activeBookingCount, setActiveBookingCount] = useState(0);
  const [failedBookingCount, setFailedBookingCount] = useState(0);
  const [inactiveBookingCount, setInactiveBookingCount] = useState(0);
  const [pendingBookingCount, setPendingBookingCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState({
    supplierAccountId: account?.id,
    startDate: '',
    endDate: '',
    buyerName: '',
    bookingNumber: urlBookingNumber,
    selectedLocation: null,
    sortBy: '',
    equipmentType: '',
    brokered: false,
    frequency: '',
    sortDir: ''
  });
  const [adjustBooking, setAdjustBooking] = useState(defaultAdjustBooking);

  const userIsAdmin = hasAdminAccess(account);

  const classes = useStyles();

  const handleTabChange = (_event, value) => {
    if (value !== undefined) {
      switch (value) {
        case '0' :
          setCurrentTab(ACTIVE_TAB)
          break;
        case '1':
          setCurrentTab(PENDING_TAB)
          break;
        case '2':
          setCurrentTab(INACTIVE_TAB)
          break;
        case '3':
          setCurrentTab(PAYMENT_FAILED_TAB)
          break;
        default:
          setCurrentTab(ACTIVE_TAB)
          break;
      }
    } else {
      setCurrentTab(ACTIVE_TAB)
    }
  };

  const onCloseDialog = () => {
    setAdjustBooking(defaultAdjustBooking)
  }

  const onDialogSuccess = () => {
    loadBookings(query, statuses.ACTIVE, setActiveBookings, setActiveBookingCount, activePage, rowsPerPage);
    loadBookings(query, statuses.INACTIVE, setInactiveBookings, setInactiveBookingCount, inactivePage, rowsPerPage);
    loadBookings(query, statuses.FAILED, setFailedBookings, setFailedBookingCount, failedPage, rowsPerPage);
    loadBookings(query, statuses.PENDING, setPendingBookings, setPendingBookingCount, pendingPage, rowsPerPage);
  }

  const handleSelectAdjustBooking = (booking: Booking, adjustType) => {
    setAdjustBooking({open: true, booking: booking, type: adjustType})
  }

  const handleAdjustSpaces = (spaces) => {
    const {booking} = adjustBooking;

    let newBooking = {...booking, numberOfSpaces: spaces}

    setAdjustBooking(prev => ({...prev, booking: newBooking}))
  }

  const handleAdjustEndDate = (date) => {
    const {booking} = adjustBooking;

    let newBooking = {...booking, endDate: date}

    setAdjustBooking(prev => ({...prev, booking: newBooking}));
  }

  const handleUpdatePendingBooking = (booking, updateAction) => {
    let message = `Successfully ${updateAction === updatePendingBookingActions.APPROVE ? 'Approved' : 'Declined'} Booking ${booking.orderNumber}`;

    const params = {
      status: updateAction,
      body: {
        id: booking.id
      }
    }

    updateBookingStatus(params)
      .then(() => {
        loadBookings();
        snackbarShowMessage(message);
      })
      .catch((err) => {
        snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(err), "error", 15000)
      })

  }

  const loadBookings = useCallback((
    query,
    status,
    setBookings,
    setBookingCount,
    page,
    pageSize
  ) => {
    const {id} = account;

    if (id) {
      setLoading(true);
      const requestParams = {
        supplierAccountId: account.id,
        locationId: query.selectedLocation?.id || '',
        status: status,
        page: page,
        size: pageSize,
        ...query,
      };

      getSupplierBooking(requestParams)
        .then(({body}) => {
          const {count} = body;
          setBookingCount(count);
          const data = body.content?.map((booking) => {
            return transformBooking(booking);
          });
          setBookings(data);
        })
        .catch(err => {
          snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(err), "error", 15000)
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [account, snackbarShowMessage]);

  useEffect(() => {
    loadBookings(query, statuses.ACTIVE, setActiveBookings, setActiveBookingCount, activePage, rowsPerPage);
  }, [loadBookings, query, rowsPerPage, activePage]);

  useEffect(() => {
    loadBookings(query, statuses.INACTIVE, setInactiveBookings, setInactiveBookingCount, inactivePage, rowsPerPage);
  }, [loadBookings, query, rowsPerPage, inactivePage]);

  useEffect(() => {
    loadBookings(query, statuses.FAILED, setFailedBookings, setFailedBookingCount, failedPage, rowsPerPage);
  }, [loadBookings, query, rowsPerPage, failedPage]);

  useEffect(() => {
    loadBookings(query, statuses.PENDING, setPendingBookings, setPendingBookingCount, pendingPage, rowsPerPage);
  }, [loadBookings, query, rowsPerPage, pendingPage]);

  useEffect(() => {
    if (urlStatus) {
      setCurrentTab(getDefaultTab(urlStatus))
    } else if (urlBookingNumber) {
      if (activeBookings.length) {
        setCurrentTab(getDefaultTab(statuses.ACTIVE))
      }
      if (failedBookings.length) {
        setCurrentTab(getDefaultTab(statuses.FAILED))
      }
      if (inactiveBookings.length) {
        setCurrentTab(getDefaultTab(statuses.INACTIVE))
      }
      if (pendingBookings.length) {
        setCurrentTab(getDefaultTab(statuses.PENDING))
      }
    }
  }, [urlBookingNumber, activeBookings, inactiveBookings, failedBookings, pendingBookings, getDefaultTab, urlStatus]);

  const reportUrl = getSupplierBookingCSVDownload(query);

  return (
    <Container className={classes.mainContainer}>
      <Grid item>
        <Typography variant={'h5'} component={'h1'}>Bookings</Typography>
      </Grid>
      <Grid item className={classes.filterContainer}>
        <SupplierBookingFilter setQuery={setQuery}/>
      </Grid>
      <Grid item className={classes.rightAlign}>
        {
          activeBookingCount !== 0 || inactiveBookingCount !== 0 || failedBookingCount !== 0 || pendingBookingCount !== 0 ?
            <DownloadCSVButton
              title='Download Report'
              reportUrl={reportUrl}
              onError={(err) => snackbarShowMessage(getErrorMessageForNonStandardAndStandardResponse(err, 'Failed to download report'))}
            />
            : ""
        }
      </Grid>
      <Grid item>
        <TabContext value={currentTab}>
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item xs>
              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                aria-label={'Bookings Tabs'}
                textColor={'secondary'}
                variant={'fullWidth'}
              >
                <Tab value={'0'} label={activeBookings.length ? `Active (${activeBookingCount})` : 'Active'}/>
                <Tab value={'1'} label={pendingBookings.length ? `Pending (${pendingBookingCount})` : 'Pending'}/>
                <Tab value={'2'} label={inactiveBookings.length ? `Inactive (${inactiveBookingCount})` : 'Inactive'}/>
                <Tab value={'3'} label={failedBookings.length ? `Payment Failed (${failedBookingCount})` : 'Payment Failed'}/>
              </Tabs>
            </Grid>
          </Grid>

          <TabPanel value={'0'} className={classes.tabPanel}>
            <BookingTab
              bookings={activeBookings}
              loading={loading}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={activePage}
              setPage={setActivePage}
              count={activeBookingCount}
              status={statuses.ACTIVE}
              userIsAdmin={userIsAdmin}
              handleSelectAdjustBooking={handleSelectAdjustBooking}
            />
          </TabPanel>
          <TabPanel value={'1'} className={classes.tabPanel}>
            <BookingTab
              bookings={pendingBookings}
              loading={loading}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={pendingPage}
              setPage={setPendingPage}
              count={pendingBookingCount}
              status={statuses.PENDING}
              userIsAdmin={userIsAdmin}
              handleSelectAdjustBooking={handleSelectAdjustBooking}
              handleUpdatePendingBooking={handleUpdatePendingBooking}
            />
          </TabPanel>
          <TabPanel value={'2'} className={classes.tabPanel}>
            <BookingTab
              bookings={inactiveBookings}
              loading={loading}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={inactivePage}
              setPage={setInactivePage}
              count={inactiveBookingCount}
              status={statuses.INACTIVE}
              userIsAdmin={userIsAdmin}
              handleSelectAdjustBooking={handleSelectAdjustBooking}
            />
          </TabPanel>
          <TabPanel value={'3'} className={classes.tabPanel}>
            <BookingTab
              bookings={failedBookings}
              loading={loading}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={failedPage}
              setPage={setFailedPage}
              count={failedBookingCount}
              status={statuses.FAILED}
              userIsAdmin={userIsAdmin}
              handleSelectAdjustBooking={handleSelectAdjustBooking}
            />
          </TabPanel>
        </TabContext>
      </Grid>
      <AdjustBookingDialog
        open={adjustBooking.open}
        onConfirm={onDialogSuccess}
        onClose={onCloseDialog}
        type={adjustBooking.type}
        booking={adjustBooking.booking}
        handleAdjustEndDate={handleAdjustEndDate}
        handleAdjustSpaces={handleAdjustSpaces}
      />
    </Container>
  );
};

export default withSnackbar(SupplierBooking);
