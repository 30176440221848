import request from '../../util/SuperagentUtils';
import { logout } from "../../util/LogoutUtil";

const handleFailure = (response) => {
  if (!response.ok) {
    throw Error(`${response.status} - ${response.statusText}`);
  }
  if (response.status === 401) {
    logout();
  }
  return response;;
}

export const requestGetFailedTransactionsForBooking = (bookingId : String,
                                                       onSuccess?: (failedTransactions: Array<Object>) => void,
                                                       onFail?: (err: Object) => void) : (Promise<Object> | void) => {
    const result = request
        .get(`/api/booking/${bookingId}/transactions/failed`)

    if(onSuccess && onFail) {
        result
            .then((resp) => onSuccess(resp.body))
            .catch((err) => onFail(err))
    } else {
        return result
    }
}

export const requestBookingById = async (bookingId) => {
  return handleFailure(request.get(`/api/booking/${bookingId}`))
}