import { makeStyles } from "@mui/styles";
import {Theme} from "@mui/material";

const useStyles: (theme: Theme) => {
    blueCard: CSSStyleSheet,
    title: CSSStyleSheet,
    container: CSSStyleSheet,
    section: CSSStyleSheet,
    heading: CSSStyleSheet,
    lpDivider: CSSStyleSheet,
    mainContainer: CSSStyleSheet,
    lpListingInfoSection: CSSStyleSheet,
} = makeStyles((theme) => ({
    blueCard: {
        backgroundColor: 'rgba(216, 248, 255, 1)'
    },
    mainSection:{
        backgroundColor: '#fff'
    },
    title: {
      fontWeight: 600,
      margin: '0.5em 0 1.5em 0'
    },
    container: {
        maxWidth: '97.14em !important',
        margin: '0 auto',
    },
    section: {
        padding: '2em 0em',
    },
    heading: {
        fontSize: '1.43em',
        fontWeight: '600',
        fontFamily: 'Inter',
        lineHeight: '2.28em',
        letterSpacing: '0.01em',
    },
    lpDivider: {
        height: '0.07rem',
        color: '#EEEEEE',
        marginTop: '1em',
    },
    mainContainer: {
        columnGap: '2.3em',
        marginTop: '2em',
        marginBottom: '8em',
    },
    lpListingInfoSection: {
        [theme.breakpoints.between('md', 'lg')]: {
            width: '57.14rem',
        }
    },
}));

const transactionDetailsStyles = makeStyles((theme) => ({
    container: {
        boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.05)',
        borderRadius: '0.5em',
        padding: '1.5em',
    },
    heading: {
        fontSize: '1.71em',
        fontWeight: '500',
        lineHeight: '2.28em',
        fontFamily: 'Inter',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    detailsImg:{
        width: '100%',
        height: '11.42em',
        objectfit: 'cover',
        borderRadius: '0.5em',
    },
    requestType:{
        width: 'fit-content',
        marginTop: '1em',
    },
    title:{
        fontSize: '1.14em',
        fontWeight: '500',
        lineHeight: '1.71em',
        fontFamily: 'Inter',
        color: 'rgba(0, 0, 0, 0.87)',
        letteSpacing: '0.03em',
    },
    icon:{
        color: 'rgba(0, 0, 0, 0.54)',
        marginRight: '0.2em',
    },
    cityState:{
        fontSize: '1em',
        fontWeight: '400',
        lineHeight: '1.42em',
        fontFamily: 'Inter',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    bookDetailsRow:{
        borderBottom: '1px solid rgba(238, 238, 238, 1)',
        marginTop: '1.5em',
        paddingBottom: '0.5em !important',
    },
    bookingDetailsLabel:{
        fontSize: '0.85em',
        color: 'rgba(0, 0, 0, 0.6)',
        fontWeight: '400',
        fontFamily: 'Inter',
        textTransform: 'uppercase',
    },
    bookingDetailsTitle:{
        fontSize: '1.14em',
        fontWeight: '500',
        lineHeight: '1.71em',
        fontFamily: 'Inter',
    },
    transactionLabel:{
        fontSize: '1.14em',
        fontWeight: '500',
        lineHeight: '1.71em',
        fontFamily: 'Inter',
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
    titleRowTitle:{
        fontSize: '1.14em',
        fontWeight: '500',
        lineHeight: '1.71em',
        fontFamily: 'Inter',
        color: 'rgba(0, 0, 0, 0.6)',
        letteSpacing: '0.03em',
    },
    marginTop:{
        marginTop: '1.5em',
    }
}));


export {
    useStyles,
    transactionDetailsStyles
}