const BuyerBookingTabs = Object.freeze({
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
});

const BuyerBookingTabVerbiage = Object.freeze({
  [BuyerBookingTabs.ACTIVE]: {
    label: 'Active',
  },
  [BuyerBookingTabs.INACTIVE]: {
    label: 'Inactive',
    title: 'A booking that is expired, canceled, or declined.',
    status: 'Inactive',
    paymentActionLabel: 'Update Payment Method',
  },
  [BuyerBookingTabs.PENDING]: {
    label: 'Pending',
  },
  [BuyerBookingTabs.PAYMENT_FAILED]: {
    label: 'Payment Failed',
    title: 'A booking that has failed due to insufficient funds or a bank dispute. The booking cannot be used until the buyer submits payment.',
    status: 'Payment Denied',
    paymentActionLabel: 'Make Payment',
  },
});

const headerCells = [
  { id: 'orderNumber', label: 'BOOKING NUMBER' },
  { id: 'frequencyValue', label: 'FREQUENCY' },
  { id: 'bookedDates', label: 'BOOKED DATES' },
  { id: 'locationName', label: 'LOCATION' },
  { id: 'recurringCharge', label: 'MONTHLY CHARGE' },
  { id: 'status', label: 'STATUS' },
  { id: 'supplierCompanyName', label: 'SUPPLIER' },
  { id: 'numberOfSpaces', label: 'SPACES BOOKED' },
  { id: 'paymentSchedule', label: 'PAYMENT SCHEDULE' },
  { id: 'initialCharge', label: 'INITIAL CHARGE' },
  { id: 'paymentMethodDescription', label: 'PAYMENT METHOD' },
  { id: 'assetType', label: 'EQUIPMENT TYPE' },
  { id: 'createdOn', label: 'CREATED ON' },
];

const setBuyerBookingTab = (booking: Booking) =>  {
  if (booking.active) {
    booking.buyerBookingTab = BuyerBookingTabs.ACTIVE;
  } else if (booking.status === "Pending" || booking.status === "Incomplete") {
    booking.buyerBookingTab = BuyerBookingTabs.PENDING;
  } else if (booking.status === 'Payment Declined') {
    booking.buyerBookingTab = BuyerBookingTabs.PAYMENT_FAILED;
  } else {
    booking.buyerBookingTab = BuyerBookingTabs.INACTIVE;
  }
};

export {
  BuyerBookingTabs,
  BuyerBookingTabVerbiage,
  headerCells,
  setBuyerBookingTab
}