import React, { useState } from 'react';
import { Button, Grid, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import Busy from "../../../../Busy";
import {
  requestCompleteRefundWithDeduction,
  requestCompleteRefundWithoutDeduction,
  requestDenyRefund
} from "../../../request/invoice-requests";
import { CallbackActions } from "../InvoiceActions";
import {getErrorMessageForStandardResponse} from "../../../../../util/NetworkErrorUtil";

const RefundRequestResponseOptions = [
  {
    test: (invoice) => invoice?.locationIsLive,
    label: 'Complete Refund (Deduct From Next Payout)',
    requester: requestCompleteRefundWithDeduction,
    submitLabel: 'Approve Refund',
    success: 'Successfully completed refund! Refund Invoice: ',
    testFailure: 'Location is no longer live. Manually Invoice.'
  },
  {
    test: (invoice) => !!invoice,
    label: 'Complete Refund (DO NOT Deduct From Next Payout',
    requester: requestCompleteRefundWithoutDeduction,
    submitLabel: 'Approve Refund',
    success: 'Successfully completed refund! Refund Invoice: ',
    testFailure: ''
  },
  {
    test: (invoice) => !!invoice,
    label: 'Deny Refund',
    requester: requestDenyRefund,
    submitLabel: 'Deny Refund',
    success: 'Successfully denied refund! Refund Invoice: ',
    testFailure: ''
  },
];

const ManageRequestRefundModal = (props: {
  invoice: {transactionId: string, locationIsLive: boolean},
  open: boolean,
  onInvoiceAction: (callbackActions: any) => void,
  onClose: (invoice: {transactionId: string}) => void,
}) => {
  const { invoice, open, onInvoiceAction, onClose } = props;
  const [submitLabel, setSubmitLabel] = useState('');

  const handleClose = () => {
    onClose();
  };

  const onRadioChange = (event) => {
    const index = parseInt(event.target.value);
    setSubmitLabel(RefundRequestResponseOptions[index].submitLabel);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData).entries());
    const index = parseInt(formJson.refundRequestResponse);
    const requester = RefundRequestResponseOptions[index].requester;

    Busy.set(true);
    requester(invoice).then(() => {
      onClose(CallbackActions.SUCCESS, invoice, RefundRequestResponseOptions[index].success + invoice?.transactionNumber);
    }).catch((error) => {
      onInvoiceAction(CallbackActions.ERROR, invoice, getErrorMessageForStandardResponse(error));
    }).finally(() => {
      Busy.set(false);
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit
      }}
    >
      <DialogTitle>
        Respond to Refund Request
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          name={"refundRequestResponse"}
          onChange={onRadioChange}
        >
          {RefundRequestResponseOptions.map((refundRequestResponseOption, index) => (
            <>
              <FormControlLabel
                value={index}
                label={refundRequestResponseOption.label}
                control={<Radio disabled={!refundRequestResponseOption.test(invoice)}/>}
                error={!refundRequestResponseOption.test(invoice)}
              />
              {!refundRequestResponseOption.test(invoice) && <Typography sx={{ ml: 3.5 }} variant="subtitle2" color="error">{refundRequestResponseOption.testFailure}</Typography>}
            </>
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"center"}>
          <Button variant="text" onClick={handleClose} >Cancel</Button>
          {invoice && submitLabel && <Button variant="contained" type="submit" >{submitLabel}</Button>}
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
export default ManageRequestRefundModal;
