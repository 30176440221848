import React, {useState, useRef } from 'react'
import RedesignAddPaymentMethodSetup from "../../../views/RedesignAddPaymentMethodSetup";

  /*
  * This component is a modal that allows the user to add a payment method to their account.
  * It is used in the BuyerPaymentMethodSelect component.
  *
  * Note: this component is basically just a bridge to the view RedesignAddPaymentMethodSetup
  * and is meant to basically turn that view into a component that can easily be re-used elsewhere
  * without having to set up the plethora of handlers that the view requires.
  *
  * @param {Account} account - The account object of the user
  * @param {boolean} open - A boolean that determines if the modal is open or not
  * @param {function} onClose - A function that closes the modal
  */

const AddPaymentMethodModalRedesign = ({ account, open, onClose }) => {

  const [collectMicroDepositVerifyPayment, setCollectMicroDepositVerifyPayment] = useState(false);
  const [microDepositVerify, setMicroDeposit] = useState(false);
  const [payWithAch, setACH] = useState(false);

  const ref = useRef();

  const setMicroDepositHandler = (bool) => {
    setCollectMicroDepositVerifyPayment(bool)
  }

  const setAchHandler = (bool) => {
    setACH(bool);
    setMicroDeposit(false);
    setCollectMicroDepositVerifyPayment(false)
  }

  const closePaymentModalWithoutReload = () => {
    onClose(false);
  }

  const closePaymentModalWithReload = () => {
    onClose(true);
  }

  return (
    <>
      {
        !!open ?
          <RedesignAddPaymentMethodSetup
            account={account}
            collectMicroDepositVerifyPayment={collectMicroDepositVerifyPayment}
            doneWithAddPaymentMethod={closePaymentModalWithoutReload}
            loadPaymentMethods={closePaymentModalWithReload}
            microDepositVerify={microDepositVerify}
            payWithAch={payWithAch}
            paymentModalOpen={open}
            ref={ref}
            setACH={setAchHandler}
            setMicroDeposit={setMicroDepositHandler}
            setPaymentModalOpen={() => { /* null operation */ }}
          />
        :
          null
      }
    </>
  )
}

export default AddPaymentMethodModalRedesign
