export const getErrorMessageForStandardResponse = (err: Object) => {
  if (err.status) {
    const {message: errorMessage, code: errorCode} = err.response.body;
    return errorCode ? `${errorMessage}. Code: ${errorCode}` : errorMessage;
  }
  return "Unable to complete the request potentially due to network connectivity issue"
}

export const getErrorMessageForNonStandardAndStandardResponse = (err: Object, defaultErrorMsg: String = 'Request Failed') => {
  if (err?.responseJSON) {
    return err?.responseJSON?.message?.trim() || "Internal Server Error";
  }
  return getErrorMessageForStandardResponse(err, defaultErrorMsg);
}
