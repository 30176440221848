import React from 'react';
import {Box, Grid} from "@mui/material";
import { BookingLoadingSkeleton, Pagination } from '@securspace/securspace-ui-kit';
import BookingCardList from './BookingCardList';
import TextBox from '../bookingsOverview/TextBox';
import { withSnackbar } from '../hocs/withSnackbar';
import { headerCells } from './data';
import type { Booking } from '../../types/Booking';
import { statuses } from '../../views/supplier-booking/SupplierBooking';

const BookingTab = (props: { bookings: Array<Booking>, loading: boolean, page: Number,  setPage: () => {}, count: Number, status: string, rowsPerPage: Number, setRowsPerPage: () => {}, userIsAdmin: Boolean, handleSelectAdjustBooking: (booking: Booking, adjustType: {}, handleUpdatePendingBooking: () => {}) => {}}) => {
  const {bookings, loading, page, setPage, count, status, rowsPerPage, setRowsPerPage, userIsAdmin, handleSelectAdjustBooking, handleUpdatePendingBooking} = props;

  const infoBoxTitle = () => {
    switch(status) {
      case statuses.ACTIVE :
        return "An approved booking that is current or in the future.";
      case statuses.FAILED :
        return "A booking that has failed due to insufficient funds or a bank dispute. The booking cannot be used until the buyer submits payment.";
      case statuses.INACTIVE :
        return "A booking that is expired, canceled, or declined.";
      case statuses.PENDING :
        return "A booking request that is awaiting supplier approval or buyer to complete the booking.";
      default :
        return "";
    }
  }

  const shouldResetPage = (requestedPage, rowsPerPage, totalBookings) => {
    const newPageCount = Math.ceil(totalBookings / rowsPerPage);
    return requestedPage > 0 ? newPageCount < requestedPage + 1 : false;
  };

  const handleRowsPerPageChange = ({target}) => {
    if (shouldResetPage(page, target.value, count)) {
      setPage(0);
    }
    setRowsPerPage(target.value);
  }

  const handlePageChange = (_, page) => {
    setPage(page)
  }

  return (
    <Box>
      {
        loading ?
          <BookingLoadingSkeleton/>
          :
          <Box>
            <TextBox title={infoBoxTitle()}/>
            <BookingCardList bookings={bookings} headerCells={headerCells} userIsAdmin={userIsAdmin}
                             handleSelectAdjustBooking={handleSelectAdjustBooking} handleUpdatePendingBooking={handleUpdatePendingBooking}/>
          </Box>
      }
      {
        bookings?.length > 0 && <Grid item>
          <Pagination
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            variant="tablePagination"
          />
        </Grid>
      }
    </Box>
  );
};

export default withSnackbar(BookingTab);
